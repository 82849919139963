import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ padding: '2rem' }}>
      <h1 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '1rem' }}>
        Not found
      </h1>
      <p style={{ marginBottom: '10rem' }}>
        Looks like you found something that doesn&#39;t exist. Go back to the{' '}
        <Link to="/">home page.</Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
